import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { Permission } from '@core/auth/permission.enum';

@Component({
  selector: 'app-admin',
  template: `
    <app-navigation
      [membername]="memberName"
      [memberimage]="memberImageURL"
      [menus]="menus"
      (logout)="logout()"
      (password)="changePassword()"
      (profile)="gotoProfile()"
    >
      <router-outlet></router-outlet>
      <div id="recaptcha-container"></div>
    </app-navigation>
  `,
})
export class AdminComponent implements OnInit {
  menus: { title: string; path: string; icon: string }[] = [];
  memberName: string;
  memberImageURL: string;

  constructor(private router: Router, private auth: AuthService) {
    this.menus[0] = {
      title: 'Dashboard',
      path: '/admin/dashboard',
      icon: 'insert_chart',
    };

    this.memberName = this.auth.currentUser?.firstName || '';
    this.memberImageURL = this.auth.currentUser?.org?.imageURL || '';
    this.initMenus();
  }

  ngOnInit() {
    // const currentUser = this.auth.currentUser;
    // const currentAuth = this.auth.currentAuthUser;
    // if (currentUser?.phoneNumber && !currentAuth?.phoneNumber) {
    //   this.promptPhoneNumber(currentUser.phoneNumber);
    // }
  }

  // promptPhoneNumber(phoneNumber: string) {
  //   const dialogRef = this.dialog.open(VerifyPhoneDialogComponent, {
  //     maxWidth: '370px',
  //     data: {
  //       phoneNumber,
  //     },
  //     disableClose: true,
  //   });

  //   dialogRef.afterClosed().subscribe((res) => {
  //     if (res) {
  //       window.location.href = '/';
  //     }
  //   });
  // }

  async initMenus() {
    const scope = await this.auth.getTokenScope();

    if (scope.includes(Permission.TourRead)) {
      // this.menus.push({
      //   title: 'Photos',
      //   path: '/admin/photos',
      //   icon: 'image',
      // });
      this.menus.push({
        title: 'Itineraries',
        path: '/admin/itineraries',
        icon: 'map',
      });
      this.menus.push({ title: 'Tours', path: '/admin/tours', icon: 'villa' });
    }

    if (scope.includes(Permission.OrderRead)) {
      this.menus.push({
        title: 'Orders',
        path: '/admin/orders',
        icon: 'local_mall',
      });
    }

    if (scope.includes(Permission.OrgRead)) {
      this.menus.push({
        title: 'Agents',
        path: '/admin/orgs',
        icon: 'store',
      });
    }

    if (scope.includes(Permission.PromotionRead)) {
      this.menus.push({
        title: 'Coupons',
        path: '/admin/coupons',
        icon: 'redeem',
      });
    }

    if (scope.includes('post:Read')) {
      this.menus.push({ title: 'Posts', path: '/admin/posts', icon: 'style' });
    }

    if (scope.includes(Permission.UserRead)) {
      this.menus.push({
        title: 'Users',
        path: '/admin/users',
        icon: 'account_box',
      });
    }

    if (scope.includes(Permission.RoleRead)) {
      this.menus.push({ title: 'Roles', path: '/admin/roles', icon: 'policy' });
    }
  }

  async logout() {
    await this.auth.logout();
    this.router.navigate(['/auth/login']);
  }

  changePassword() {
    this.router.navigate(['/admin/profile/change-password']);
  }

  gotoProfile() {
    this.router.navigate(['/admin/profile/edit']);
  }
}
