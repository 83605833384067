import { Directive, Inject, Input } from '@angular/core';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { DayRangeSelectionStrategy } from './day-range-selection.strategy';

@Directive({
  selector: '[dayRange]',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DayRangeSelectionStrategy,
    },
  ],
})
export class DayRangeDirective {
  constructor(
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private dayRangeStrategy: DayRangeSelectionStrategy<any>
  ) {}
  @Input() set dayRange(value: number) {
    this.dayRangeStrategy.dayRange = +value || 7;
  }
}

// source: https://stackoverflow.com/questions/64521480/angular-material-datepicker-limit-the-range-selection
