export enum Permission {
  // Post
  PostCreate = 'post:Create',
  PostUpdate = 'post:Update',
  PostDelete = 'post:Delete',
  PostRead = 'post:Read',
  // Role
  RoleWrite = 'admin/roles:write',
  RoleRead = 'admin/roles:read',
  // User
  UserWrite = 'admin/users:write',
  UserRead = 'admin/users:read',
  // Tour
  TourRead = 'admin/tours:read',
  TourWrite = 'admin/tours:write',
  // Order
  OrderRead = 'admin/orders:read',
  OrderWrite = 'admin/orders:write',
  // Org
  OrgRead = 'admin/orgs:read',
  OrgWrite = 'admin/orgs:write',
  // Promotion
  PromotionRead = 'admin/promotions:read',
  PromotionWrite = 'admin/promotions:write',
}
