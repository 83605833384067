import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatGridListModule } from '@angular/material/grid-list';

import { DialogComponent } from './dialog/dialog.component';
import { LoadingComponent } from './loading/loading.component';
import { MapComponent } from './map/map.component';
import { NgMapsCoreModule } from '@ng-maps/core';
import { OpeningHoursComponent } from './opening-hours/opening-hours.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { EditorComponent } from './editor/editor.component';
import { GuidePriceComponent } from './guide-price/guide-price.component';
import { MediaDialogComponent } from './media-dialog/media-dialog.component';
import { PricePipe } from './pipes/price.pipe';
import { NgMapsGoogleModule } from '@ng-maps/google';
import { OverlayActionComponent } from './overlay-action/overlay-action.component';
import { DayRangeDirective } from './day-range-picker/day-range.directive';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';

const commonModules = [
  CommonModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  EditorModule,
  NgMapsCoreModule,
  NgMapsGoogleModule,
];

const materialModules = [
  MatToolbarModule,
  MatTableModule,
  MatButtonModule,
  MatPaginatorModule,
  MatSortModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  LayoutModule,
  MatCardModule,
  MatDividerModule,
  MatProgressBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatDialogModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatMenuModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatTabsModule,
  MatGridListModule,
];

const sharedComponents = [
  DialogComponent,
  LoadingComponent,
  MapComponent,
  OpeningHoursComponent,
  EditorComponent,
  GuidePriceComponent,
  MediaDialogComponent,
  PricePipe,
  OverlayActionComponent,
  DayRangeDirective,
  InfoDialogComponent,
];

@NgModule({
  imports: [...commonModules, ...materialModules],
  exports: [...commonModules, ...materialModules, ...sharedComponents],
  declarations: [...sharedComponents, TimePickerComponent],
  entryComponents: [
    DialogComponent,
    OpeningHoursComponent,
    GuidePriceComponent,
    MediaDialogComponent,
    InfoDialogComponent,
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
})
export class SharedModule {}
