import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { FileService } from '@core/file/file.service';
import { getSquareMediumImageUrl, removeExtension } from '@shared/utils';

export type Photo = {
  id: string;
  fileName: string;
  previewURL: string;
  selected?: boolean;
  loading?: boolean;
};

export class PhotosDataSource implements DataSource<Photo> {
  public itemsSubject = new BehaviorSubject<Photo[]>([]);
  private loadingSubject = new BehaviorSubject(false);
  private filterSubject = new BehaviorSubject('');

  public loading$ = this.loadingSubject.asObservable();
  public nextToken = '';
  public prevTokens: string[] = [];

  constructor(private fileService: FileService, private queryFilter?: any) {}

  get filter() {
    const searchStr = this.filterSubject.getValue();
    const term = searchStr.trim(); // .toLowerCase();

    const filter = {
      search: term,
    };

    return term ? filter : this.queryFilter || null;
  }

  hasNext() {
    return !!this.nextToken;
  }

  hasPrev() {
    return !!this.prevTokens.length;
  }

  connect() {
    return this.itemsSubject.asObservable();
  }

  disconnect() {
    this.itemsSubject.complete();
    this.loadingSubject.complete();
  }

  search(str) {
    const prevStr = this.filterSubject.value;
    if (prevStr != str) {
      this.filterSubject.next(str);
      this.loadData();
    }
  }

  applyFilter(filter) {
    this.queryFilter = filter;
    this.loadData();
  }

  addData(data: Photo) {
    const updatedData = [...this.itemsSubject.value];
    updatedData.unshift(data);
    this.itemsSubject.next(updatedData);
  }

  updateData(tempId: string, data: Photo) {
    const updatedData = [...this.itemsSubject.value].map((e) => {
      if (e.id === tempId) {
        return { ...e, ...data };
      }
      return e;
    });
    this.itemsSubject.next(updatedData);
  }

  loadData(pageSize: number = 10, nextToken?: string, isPrev?: boolean) {
    this.loadingSubject.next(true);

    this.fileService
      .listFiles({
        limit: pageSize,
        nextToken,
        search: this.filter ? this.filter.search : '',
      })
      .pipe(first())
      .subscribe((result) => {
        if (result.data) {
          this.loadingSubject.next(false);
          this.itemsSubject.next(
            result.data.map((e) => {
              const mediumURL = getSquareMediumImageUrl(e.s3URL);

              return {
                id: e.id,
                fileName: removeExtension(e.fileName),
                previewURL: mediumURL,
                imageURL: mediumURL,
              };
            })
          );
          this.nextToken = result?.meta?.nextToken || '';
          if (nextToken && !isPrev) {
            this.prevTokens.push(nextToken);
          }
        }
      });
  }

  next(pageSize: number) {
    this.loadData(pageSize, this.nextToken);
  }

  prev(pageSize: number) {
    this.prevTokens.pop();
    this.loadData(
      pageSize,
      this.prevTokens?.[this.prevTokens.length - 1],
      true
    );
  }
}
