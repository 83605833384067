import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { from, Subject, of } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ApiResponse } from '@core/api';
import { User } from '@core/user/user.model';
import { RegisterAgentRequest } from './models/register-agent-request';
import { Org } from '@core/user/org.model';
import { Permission } from './permission.enum';

export interface CurrentUser {
  id?: string;
  role?: any;
  authId: string;
  countryCode: string;
  firstName: string;
  imageURL: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  org: Org;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser: CurrentUser | null;
  public currentAuthUser: firebase.User | null;
  public authenticated = new Subject<boolean>();

  constructor(private afAuth: AngularFireAuth, private http: HttpClient) {
    this.afAuth.authState
      .pipe(
        switchMap((res) => (res ? this.updateToken(res) : of(null))),
        switchMap((res) => (res ? this.me() : of(null))),
        tap((res) => {
          this.currentUser = res;
          this.authenticated.next(res ? true : false);
        })
      )
      .subscribe();
  }

  isAuthenticated() {
    return this.authenticated.asObservable();
  }

  me() {
    return this.http
      .get<ApiResponse<CurrentUser>>(environment.userApiURL + '/me')
      .pipe(map((res) => res.data));
  }

  refetchCurrentUser() {
    return this.me().pipe(
      tap((res) => {
        this.currentUser = res;
        this.authenticated.next(res ? true : false);
      })
    );
  }

  login({ email, password }) {
    return from(this.afAuth.signInWithEmailAndPassword(email, password)).pipe(
      switchMap((res) => {
        if (!res.user) {
          return of(null);
        }

        return from(res.user.getIdToken()).pipe(
          switchMap((token) => this.exchangeToken(token)),
          switchMap((newToken) => this.afAuth.signInWithCustomToken(newToken)),
          switchMap((res) => this.checkAdmin(res)),
          switchMap((res) => (res.user ? this.updateToken(res.user) : of(null)))
        );
      })
    );
  }

  register(data: RegisterAgentRequest & { email; password }) {
    return from(
      this.afAuth.createUserWithEmailAndPassword(data.email, data.password)
    ).pipe(
      switchMap((res) => {
        if (!res.user) {
          return of(null);
        }

        return from(res.user.getIdToken()).pipe(
          switchMap((token) =>
            this.registerAgent(data, token).pipe(
              switchMap((_) => this.exchangeToken(token)),
              switchMap((newToken) =>
                this.afAuth.signInWithCustomToken(newToken)
              ),
              switchMap((res) => this.checkAdmin(res)),
              switchMap((res) =>
                res.user ? this.updateToken(res.user) : of(null)
              )
            )
          )
        );
      })
    );
  }

  registerAgent(data: RegisterAgentRequest, token: string) {
    return this.http
      .post<ApiResponse<User>>(
        environment.userApiURL + '/agent/register',
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .pipe(map((res) => res.data));
  }

  loginGoogle() {
    return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  // Returns verificationId
  sendPhoneVerificationCode(
    phoneNumber: string,
    appVerifier: firebase.auth.RecaptchaVerifier
  ) {
    const provider = new firebase.auth.PhoneAuthProvider();

    return provider.verifyPhoneNumber(phoneNumber, appVerifier);
  }

  verifyPhoneVerificationCode(
    verificationId: string,
    verificationCode: string
  ) {
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );

    // return from(res.user.getIdToken()).pipe(
    //   switchMap((token) => this.exchangeToken(token)),
    //   switchMap((newToken) => this.afAuth.signInWithCustomToken(newToken)),
    //   switchMap((res) => (res.user ? this.updateToken(res.user) : of(null)))
    // );

    return this.afAuth.currentUser.then((u) =>
      u?.updatePhoneNumber(phoneCredential)
    );
  }

  loginFacebook() {
    return this.afAuth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    );
  }

  logout() {
    return this.afAuth.signOut();
  }

  createUserWithPassword(email, password) {
    return from(this.afAuth.createUserWithEmailAndPassword(email, password));
  }

  resetPassword(email) {
    return from(this.afAuth.sendPasswordResetEmail(email));
  }

  signup({ email, fullName, password, username }) {
    return this.createUserWithPassword(email, password).pipe(
      switchMap((res) => from(res.user?.getIdToken() || '')),
      switchMap((res) => {
        if (!res) {
          return of(null);
        }
        return this.createDbUser({ email, fullName, idToken: res, username });
      })
    );
  }

  createDbUser({ email, fullName, idToken, username }) {
    return this.http
      .post<ApiResponse<User>>(environment.userApiURL + '/auth/register', {
        email,
        fullName,
        idToken,
        username,
      })
      .pipe(map((res) => res.data));
  }

  exchangeToken(token: string) {
    return this.http
      .get<ApiResponse<{ newToken: string }>>(
        environment.userApiURL + '/exchange-token',
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .pipe(map((res) => res.data.newToken));
  }

  async checkAdmin(cred: firebase.auth.UserCredential) {
    if (!cred.user) {
      throw new Error('Login failed');
    }

    const decoded = await cred.user.getIdTokenResult();
    if (
      decoded.claims['group'] !== 'admin' &&
      decoded.claims['group'] !== 'super-admin'
    ) {
      await this.logout();
      throw new Error('Unauthorized access');
    }

    return cred;
  }

  updateToken(user: firebase.User) {
    return from(user.getIdTokenResult()).pipe(
      switchMap((decoded) => {
        // const exp = new Date(decoded.expirationTime);
        // const iss = new Date(decoded.issuedAtTime);
        // const now = new Date();

        if (decoded.signInProvider !== 'custom') {
          return this.exchangeToken(decoded.token).pipe(
            switchMap((newToken) =>
              this.afAuth.signInWithCustomToken(newToken)
            ),
            switchMap((res) => this.checkAdmin(res)),
            switchMap((res) =>
              res.user ? res.user.getIdTokenResult() : of(null)
            ),
            tap(() => {
              localStorage.setItem('token', decoded.token);
              this.currentAuthUser = user;
            })
          );
        }

        localStorage.setItem('token', decoded.token);
        this.currentAuthUser = user;

        return of(user);

        // if (exp > now) {
        //   console.log('settokens', decoded.token);
        //   localStorage.setItem('token', decoded.token);
        //   return of(user);
        // } else {
        //   console.log('can refresh?');
        //   const aweek = new Date();
        //   aweek.setDate(aweek.getDate() - 7);

        //   if (iss > aweek) {
        //     console.log('refreshing');
        //     return from(this.refreshToken()).pipe(map(() => user));
        //   } else {
        //     console.log('cant refresh');
        //     return from(this.logout()).pipe(map(() => user));
        //   }
        // }
      })
    );
  }

  async getToken() {
    return (await this.afAuth.currentUser)?.getIdToken();
  }

  async getTokenScope(): Promise<string> {
    const jwt = await (await this.afAuth.currentUser)?.getIdTokenResult();
    return jwt?.claims?.['scope'] || '';
  }

  async hasPermission(value: Permission): Promise<boolean> {
    const scope = await this.getTokenScope();
    return scope.includes(value);
  }

  async refreshToken() {
    return (await this.afAuth.currentUser)?.getIdToken();
  }
}
