import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileService } from '@core/file/file.service';
import { getSquareMediumImageUrl } from '@shared/utils';
import {
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  lastValueFrom,
} from 'rxjs';
import { Photo, PhotosDataSource } from './photos-datasource';

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss'],
})
export class MediaDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MediaDialogComponent>,
    private fileService: FileService,
    private mediaObserver: MediaObserver,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { preview: string; dimension: string }
  ) {}

  @ViewChild('filter') filter: ElementRef;

  selectedFiles: Photo[] = [];
  dataSource: PhotosDataSource;
  pageSize = 12;

  searchSubscription: any;
  gridCols: number = 6;

  ngOnInit() {
    this.dataSource = new PhotosDataSource(this.fileService);

    if (this.mediaObserver.isActive('xs')) {
      this.gridCols = 3;
      this.pageSize = 9;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.dataSource.loadData(this.pageSize), 0);

    this.searchSubscription = fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((r) => {
        if (!this.dataSource) {
          return;
        }

        const value = this.filter.nativeElement.value.trim();
        this.dataSource.search(value);
      });
  }

  onNext() {
    this.dataSource.next(this.pageSize);
  }

  onPrev() {
    this.dataSource.prev(this.pageSize);
  }

  onSelect(file: Photo) {
    const selectedFiles: Photo[] = [];
    const updatedData = this.dataSource.itemsSubject.value.map((e) => {
      if (e.fileName === file.fileName) {
        e.selected = !e.selected;
      }
      if (e.selected) {
        selectedFiles.push(e);
      }
      return e;
    });
    this.selectedFiles = selectedFiles;
    this.dataSource.itemsSubject.next(updatedData);
  }

  onSave() {
    this.dialogRef.close(this.selectedFiles);
  }

  onFileChange(event) {
    const file: File = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          // do something with reader.result
          const tempId = Date.now().toString();
          this.dataSource.addData({
            id: tempId,
            fileName: file.name,
            previewURL: reader.result as string,
            loading: true,
          });

          this.uploadFile(tempId, file);
        };

        img.src = reader.result as string;
      };

      reader.readAsDataURL(file);
    }
  }

  async uploadFile(tempId: string, file: File) {
    try {
      const res = await lastValueFrom(this.fileService.uploadFile(file));
      if (res.data) {
        this.dataSource.updateData(tempId, {
          id: res.data.id,
          fileName: res.data.fileName,
          imageURL: getSquareMediumImageUrl(res.data.s3URL),
          loading: false,
        } as any);
      }
    } catch (err: any) {
      console.log('err', err);
      if (err?.error?.message) {
        this.snackbar.open(err.error.message, 'Close');
      }
    }
  }
}
